import { inject } from '@angular/core';
import {
  AbstractGenericBaseClass, DiscoverSeoInfo, GenericBaseClass,
} from 'in-time-core';
import { MetaService } from '../../meta/meta.service';
import { Router } from '@angular/router';

export function MetaMixin<TClass, TBase extends GenericBaseClass<TClass> | AbstractGenericBaseClass<TClass>>(BaseClass: TBase) {
  abstract class Result extends BaseClass {
    protected readonly metaService: MetaService = inject(MetaService);
    protected readonly router: Router = inject(Router);

    setGenericMetaTags(): void {
      this.metaService.setTitle('In Time');
      this.metaService.setTag('og:url', 'https://in-time.hu/');
      this.metaService.setTag('og:title', 'In Time');
      this.metaService.setTag('og:description', 'Minden pillanat számít!');
      this.metaService.setTag('description', 'Minden pillanat számít!');
      this.metaService.setTag('og:image', '/assets/images/in-time-banner.jpg');
      this.metaService.setTag('og:image:type', 'image/jpg' );

      this.metaService.setTag('twitter:card', 'summary');
      this.metaService.setTag('twitter:title', 'In Time');
      this.metaService.setTag('twitter:description', 'Minden pillanat számít!');
      this.metaService.setTag('twitter:image', '/assets/images/in-time-banner.jpg');
      this.metaService.setTag('twitter:url', 'https://in-time.hu/');
    }

    setDiscoverMetaTags(seoInfo: DiscoverSeoInfo | null): void {
      this.metaService.setTitle(seoInfo?.title ?? 'In Time');
      this.metaService.setTag('og:url', seoInfo?.url ?? ('https://in-time.hu' + this.router.url));

      this.metaService.setOptionalTag('description', seoInfo?.description);
      this.metaService.setOptionalTag('og:title', seoInfo?.title);
      this.metaService.setOptionalTag('og:image', seoInfo?.image);
      this.metaService.setOptionalTag('og:description', seoInfo?.description);
      this.metaService.setOptionalTag('twitter:title', seoInfo?.title);
      this.metaService.setOptionalTag('twitter:image', seoInfo?.image);
      this.metaService.setOptionalTag('twitter:description', seoInfo?.description);
      this.metaService.setTag('twitter:card', 'summary');
      this.metaService.setTag('og:image:type', 'image/jpg');
    }

    setCustomMetaTags({ title, description, image, url }: {
      title: string | null,
      description: string | null,
      image: string | null,
      url: string | null,
    }) {
      this.metaService.setTitle(title ?? 'In Time');
      this.metaService.setTag('og:url', url ?? ('https://in-time.hu' + this.router.url));

      this.metaService.setOptionalTag('description', description);
      this.metaService.setOptionalTag('og:title', title);
      this.metaService.setOptionalTag('og:image', image);
      this.metaService.setOptionalTag('og:description', description);
      this.metaService.setOptionalTag('twitter:title', title);
      this.metaService.setOptionalTag('twitter:image', image);
      this.metaService.setOptionalTag('twitter:description', description);
      this.metaService.setTag('twitter:card', 'summary');
      this.metaService.setTag('og:image:type', 'image/jpg');
    }
  }

  return Result;
}